

















































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { Profile, WebsiteLevel, WebsiteProfileDisplayType } from 'client-website-ts-library/types';
import ProfileCards from '@/components/ProfileCards.vue';
import Loader from '@/components/UI/Loader.vue';

import NavigationLink from '../components/Common/NavigationLink.vue';
import Button from '../components/UI/Button.vue';

@Component({
  components: {
    NavigationLink,
    Button,
    ProfileCards,
    Loader,
  },
})
export default class About extends Mixins(View) {
  public profiles: Profile[] = [];

  mounted() {
    API.Profiles.Search(
      new ProfileFilter({
        SearchLevel: WebsiteLevel.Office,
        SearchGuid: Config.Website.Settings!.WebsiteId,
      }),
      true,
    ).then((profiles) => {
      this.profiles = profiles;
    });
  }

  get propertySales(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Principal || p.DisplayType === WebsiteProfileDisplayType.CommercialAgent || p.DisplayType === WebsiteProfileDisplayType.Agent);
  }

  get propertyManagement(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PropertyManager);
  }

  get administration(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PersonalAssistant || p.DisplayType === WebsiteProfileDisplayType.Support);
  }
}
